import { NavLink } from "react-router-dom";

const AppHeader = () => (
  <header className="app-header">
    <nav>
      <NavLink className="app-logo" to="/" strict exact>
        UK Crime Compare
      </NavLink>
      <NavLink className="link" to="/information" strict exact>
        i
      </NavLink>
    </nav>
  </header>
);

export default AppHeader;
