const CrimeInfo = ({ crimeData, postcodeData }) => (
  <div>
    {crimeData.location_crimes.length > 0 && (
      <>
        <h3>Getting crime data...</h3>
        <ul>
          {postcodeData.map((postcode, index) => (
            <li key={index}>Received a month's data for {postcode.postcode}</li>
          ))}
        </ul>
      </>
    )}
  </div>
);
export default CrimeInfo;
