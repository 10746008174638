import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const titlestring = `UK Crime Compare | 404`;

const NotFoundView = () => (
  <div className="app-body">
    <Helmet>
      <title>{titlestring}</title>
      <meta name="title" content={titlestring} />
      <meta name="og:title" content={titlestring} />
      <meta name="twitter:title" content={titlestring} />
    </Helmet>
    <div className="app-content">
      <h1>404</h1>
      <p>😬 Well somethings gone wrong...</p>
      <p>
        <Link to="/">Start again</Link>
      </p>
    </div>
    <footer className="app-footer">Footer</footer>
  </div>
);

export default NotFoundView;
