import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

import HomeView from "./HomeView";
import LocationView from "./LocationView";
import LoadingView from "./LoadingView";
import StatsView from "./StatsView";

const views = [
  { title: "Home", Component: HomeView },
  { title: "Location", Component: LocationView },
  { title: "Loading", Component: LoadingView },
  { title: "Stats", Component: StatsView },
];

const Main = () => {
  const [currentView, setCurrentView] = useState("Home");
  const [postcodes, setPostcodes] = useState({
    pc1: "",
    pc2: "",
  });
  const [crimeData, setCrimeData] = useState({
    categories: [],
    location_crimes: [],
  });

  useEffect(() => {
    let q_postcodes = {};

    let query = new URLSearchParams(window.location.search);

    if (query.has("pc1")) {
      query.forEach(function (value, key) {
        q_postcodes[key] = value;
      });

      setPostcodes(q_postcodes);
      setCurrentView("Loading");
    }
  }, []);

  return (
    <>
      {views.map(({ title, Component }, index) => {
        return (
          <CSSTransition
            key={index}
            in={currentView === title}
            timeout={1000}
            classNames="app-body"
            unmountOnExit
          >
            <Component
              setCurrentView={setCurrentView}
              setPostcodes={setPostcodes}
              postcodes={postcodes}
              setCrimeData={setCrimeData}
              crimeData={crimeData}
            />
          </CSSTransition>
        );
      })}
    </>
  );
};

export default Main;
