import { useState, useEffect } from "react";
import getLatLng from "../logic/getLatLng";
import getCrimes from "../logic/getCrimes";

import PostcodeInfo from "../components/loading/PostcodeInfo";
import CrimeInfo from "../components/loading/CrimeInfo";
import ProgressBar from "../components/loading/ProgressBar";
import Sorting from "../components/loading/Sorting";

const LoadingView = ({
  postcodes,
  crimeData,
  setCrimeData,
  setCurrentView,
}) => {
  const [progress, setProgress] = useState(0);
  const [postcodeData, setPostcodeData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(() => {
    setProgress(10);

    getLatLng(postcodes)
      .then((response) => {
        setPostcodeData(response);
        setProgress(40);
        return getCrimes(response);
      })
      .then((data) => {
        setCrimeData(data);
        setProgress(95);
        setLoading(true);

        setTimeout(function () {
          setProgress(100);
          setCurrentView("Stats");
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
        setError(true);
      });
  }, [postcodes, setCrimeData, setCurrentView]);

  return (
    <div className="app-body">
      <div className="app-content">
        <h2>Loading... {progress}%</h2>
        <PostcodeInfo postcodeData={postcodeData} isError={isError} />
        <CrimeInfo crimeData={crimeData} postcodeData={postcodeData} />
        {isLoading && <Sorting />}
      </div>
      <footer className="app-footer">
        <ProgressBar progress={progress} isError={isError} />
      </footer>
    </div>
  );
};

export default LoadingView;
