import { Helmet } from "react-helmet";

import ShareButton from "../components/ShareButton";

const StatsView = ({ crimeData, setCurrentView, setPostcodes }) => {
  let total_one = 0;
  let total_two = 0;

  const postcodestring =
    crimeData.location_crimes.length > 1
      ? `${crimeData.location_crimes[0].postcode} and ${crimeData.location_crimes[1].postcode}`
      : `${crimeData.location_crimes[0].postcode}`;

  const titlestring = `UK Crime Compare - ${postcodestring}`;
  const metastring = `See the past months criminal activity of ${postcodestring}`;

  const postcodes = crimeData.location_crimes.map((location_crime, index) => (
    <td key={index}>{location_crime.postcode}</td>
  ));

  const crimes = crimeData.categories.map((category, index) => {
    let pc1_number = 0;
    let pc2_number = 0;

    pc1_number = crimeData.location_crimes[0].crimes.filter(
      (crime) => crime.category === category.url
    ).length;

    total_one += pc1_number;

    if (crimeData.location_crimes.length > 1) {
      pc2_number = crimeData.location_crimes[1].crimes.filter(
        (crime) => crime.category === category.url
      ).length;

      total_two += pc2_number;
    }

    return (
      <tr key={index}>
        <td className="table__category">{category.name}</td>
        <td className={pc1_number >= pc2_number ? "highlight" : ""}>
          {pc1_number}
        </td>
        {crimeData.location_crimes.length > 1 && (
          <td className={pc1_number <= pc2_number ? "highlight" : ""}>
            {pc2_number}
          </td>
        )}
      </tr>
    );
  });

  const dates = crimeData.location_crimes.map((location_crime, index) => {
    let date =
      location_crime.crimes.length === 0
        ? crimeData.updated.date.slice(0, -3)
        : location_crime.crimes[0].month;
    return (
      <td className="table__date" key={index}>
        {date}
      </td>
    );
  });

  return (
    <div className="app-body">
      <Helmet>
        <title>{titlestring}</title>
        <meta name="title" content={titlestring} />
        <meta name="description" content={metastring} />
        <meta name="og:title" content={titlestring} />
        <meta property="og:description" content={metastring} />
        <meta name="twitter:title" content={titlestring} />
        <meta property="twitter:description" content={metastring} />
      </Helmet>
      <div className="app-content">
        <table className="table">
          <tbody>
            <tr className="table__title">
              <th>Categories</th>
              {postcodes}
            </tr>
            {crimes}
            <tr className="table__total">
              <td className="table__category">
                Total crimes: <small>(within one month)</small>
              </td>
              <td className={total_one >= total_two ? "highlight" : ""}>
                {total_one}
              </td>
              {crimeData.location_crimes.length > 1 && (
                <td className={total_one <= total_two ? "highlight" : ""}>
                  {total_two}
                </td>
              )}
            </tr>
            <tr>
              <td className="table__date-title">Data date: </td>
              {dates}
            </tr>
          </tbody>
        </table>
      </div>
      <footer className="app-footer">
        <p>
          <ShareButton />
        </p>
        <p>
          <button
            onClick={() => {
              setCurrentView("Home");
              setPostcodes({
                pc1: "",
                pc2: "",
              });
            }}
          >
            Start again
          </button>
        </p>
      </footer>
    </div>
  );
};

export default StatsView;
