import { Link } from "react-router-dom";

const Home = ({ setCurrentView }) => (
  <div className="app-body">
    <div className="app-content">
      <h1>UK Crime Compare</h1>
      <p>Ever wanted to know if an area is worse than you think?</p>
      <p>
        Find out by entering two postcodes to compare the amount crimes
        committed within a month broken down into categories.
      </p>
      <button onClick={() => setCurrentView("Location")}>Start</button>
    </div>
    <footer className="app-footer">
      <small>
        © UK Crime Compare {new Date().getFullYear()}.{" "}
        <Link to="/information">Site Info</Link>.{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>.
      </small>
    </footer>
  </div>
);

export default Home;
