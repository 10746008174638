const getPostcode = (lat, lng) => {
  let url_latlng = `https://api.postcodes.io/postcodes?lon=${lng}&lat=${lat}`;

  return fetch(url_latlng)
    .then((results) => {
      return results.json();
    })
    .then((data) => {
      const postcode = data.result[0].postcode;
      return postcode;
    })
    .catch(function (error) {
      throw new Error("Somethings gone wrong with postcode.io: " + error);
    });
};

export default getPostcode;
