import { useState } from "react";
import getPostcode from "../../logic/getPostcode";

const LocationOne = ({ setStep, setPostcodes, postcodes }) => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getPosition = function (options) {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  };

  const navigatorLocation = (event) => {
    setLoading(true);
    getPosition()
      .then((position) => {
        const lng = position.coords.longitude;
        const lat = position.coords.latitude;
        return getPostcode(lat, lng);
      })
      .then((postcode) => {
        setPostcodes({
          ...postcodes,
          pc1: postcode,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err.message);
        setError(true);
      });
  };

  return (
    <div className="ani-steps">
      <p>
        What postcode would you like to check? <br />
        {navigator.geolocation && (
          <>
            Or{" "}
            <button className="link" onClick={navigatorLocation}>
              use your current location
            </button>
            .
          </>
        )}
        {isLoading && <span className="spinner" />}
        {isError && (
          <span className="error-text">
            <br />
            Somethings gone wrong 😬
          </span>
        )}
      </p>
      <form
        className="input-wrapper"
        onSubmit={(event) => {
          event.preventDefault();
          setStep(1);
        }}
      >
        <input
          type="text"
          name="pc1"
          onChange={(event) => {
            setPostcodes({
              ...postcodes,
              pc1: event.target.value,
            });
          }}
          value={postcodes.pc1}
          placeholder="Postcode"
          maxLength="8"
        />
        <button
          className={
            postcodes.pc1.length > 5 ? "btn-submit active" : "btn-submit"
          }
          type="submit"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.061"
            height="10.71"
            viewBox="0 0 6.061 10.71"
          >
            <path
              d="M382.5,345.5l5,5-5,5"
              transform="translate(-382.146 -345.146)"
              fill="none"
            />
          </svg>
        </button>
      </form>
    </div>
  );
};

export default LocationOne;
