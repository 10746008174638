const PostcodeInfo = ({ postcodeData, isError }) => (
  <div>
    <h3>Getting postcode info...</h3>
    {postcodeData.length > 0 && (
      <ul>
        {postcodeData.map((postcode, index) => (
          <li key={index}>
            {postcode.postcode}
            <br />
            lat: {postcode.lat}, lng: {postcode.lng}
          </li>
        ))}
      </ul>
    )}
    {isError && <p className="error-text">Postcodes incorrect 😬</p>}
  </div>
);

export default PostcodeInfo;
