import { useState } from "react";

const ShareButton = () => {
  const [stateShare, setStateShare] = useState();

  const shareURL = (event) => {
    const link = (
      <>
        {" "}
        - URL: <br />
        <span>{document.location.href.replace(/^https?:\/\//, "")}</span>
      </>
    );
    if (navigator.share) {
      navigator
        .share({
          title: "Crime stats for ...",
          url: document.location.href,
        })
        .then(() => {
          setStateShare(" - Thanks for sharing!");
        })
        .catch(console.error);
    } else if (navigator.clipboard) {
      navigator.clipboard
        .writeText(document.location.href)
        .then(() => {
          setStateShare(" - URL copied to clipboard!");
        })
        .catch(setStateShare(link));
    } else {
      setStateShare(link);
    }
  };

  return (
    <>
      <button className="underline" onClick={shareURL}>
        Share
      </button>
      {stateShare}
    </>
  );
};

export default ShareButton;
