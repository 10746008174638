const getCrimes = (locations) => {
  const url_categories = "https://data.police.uk/api/crime-categories";
  const url_crime = `https://data.police.uk/api/crimes-street/all-crime`;
  const url_updated = `https://data.police.uk/api/crime-last-updated`;

  let promises = [
    fetch(url_categories).then((value) => value.json()),
    fetch(url_updated).then((value) => value.json()),
    fetch(
      `${url_crime}?lat=${locations[0].lat}&lng=${locations[0].lng}`
    ).then((value) => value.json()),
  ];

  if (locations[1]) {
    promises.push(
      fetch(
        `${url_crime}?lat=${locations[1].lat}&lng=${locations[1].lng}`
      ).then((value) => value.json())
    );
  }

  const crimes = Promise.all(promises).then((data) => {
    const categories = data[0];
    categories.shift();

    let output = {
      categories: categories,
      updated: data[1],
      location_crimes: [
        {
          postcode: locations[0].postcode,
          crimes: data[2],
        },
      ],
    };

    if (locations[1]) {
      output.location_crimes[1] = {
        postcode: locations[1].postcode,
        crimes: data[3],
      };
    }

    return output;
  });

  return crimes;
};

export default getCrimes;
