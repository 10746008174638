const LocationNav = ({ step, setStep }) => {
  return (
    <nav className="location-nav">
      <button
        onClick={() => setStep(0)}
        key={0}
        disabled={step + 1 > 0 ? false : true}
        // className={step + 1 === index ? "active" : ""}
      >
        <span></span>
      </button>
      <button
        onClick={() => setStep(1)}
        key={1}
        disabled={step + 1 > 1 ? false : true}
        // className={step + 1 === index ? "active" : ""}
      >
        <span></span>
      </button>
      <button
        onClick={() => setStep(2)}
        key={2}
        disabled={step + 1 > 2 ? false : true}
        // className={step + 1 === index ? "active" : ""}
      >
        <span></span>
      </button>
    </nav>
  );
};

export default LocationNav;
