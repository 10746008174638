const ProgressBar = ({ progress, isError }) => (
  <div className="progress-bar">
    <span
      style={{
        width: `${progress}%`,
        background: `${isError ? "red" : "#0286fa"}`,
      }}
    />
  </div>
);

export default ProgressBar;
