import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const titlestring = `UK Crime Compare | Information`;

const InformationView = () => (
  <div className="app-body">
    <Helmet>
      <title>{titlestring}</title>
      <meta name="title" content={titlestring} />
      <meta name="og:title" content={titlestring} />
      <meta name="twitter:title" content={titlestring} />
    </Helmet>
    <div className="app-content infomation-view">
      <h3>What is this?</h3>
      <p>
        Inspired by a conversation between friends UK Crime Compare was built to
        easily compare crime rates in different areas. This website is meant for
        entertainment purposes only.
      </p>
      <p>
        <strong>Postcode data:</strong>{" "}
        <a
          href="https://api.postcodes.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://api.postcodes.io
        </a>
      </p>
      <p>
        <strong>Crime data:</strong>{" "}
        <a
          href="https://data.police.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://data.police.uk/
        </a>
      </p>
      <p>Data excludes Scotland unfortunately...</p>

      <h3>Privacy</h3>
      <p>
        <Link to="/privacy-policy">View our privacy policy</Link>. The above
        API's have thier own privacy policys.
      </p>

      <h3>Data accuracy</h3>
      <p>If no data for the same months the next availible month is used.</p>
      <p>
        Data from within a 1 mile radius of postcode center. More details can be
        found at{" "}
        <a href="https://data.police.uk/" rel="noopener noreferrer">
          https://data.police.uk/
        </a>
        .
      </p>
    </div>
    <footer className="app-footer">
      <span className="pat-on-the-back">
        © UK Crime Compare {new Date().getFullYear()} | Created by{" "}
        <a href="https://www.simonhowe.dev/">Me</a>
      </span>
    </footer>
  </div>
);

export default InformationView;
