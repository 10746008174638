import { useState } from "react";
import { CSSTransition } from "react-transition-group";

import LocationNav from "../components/location/LocationNav";
import LocationCompare from "../components/location/LocationCompare";
import LocationOne from "../components/location/LocationOne";
import LocationTwo from "../components/location/LocationTwo";

const steps = [
  { id: 0, Component: LocationOne },
  { id: 1, Component: LocationCompare },
  { id: 2, Component: LocationTwo },
];

const LocationView = ({ setCurrentView, postcodes, setPostcodes }) => {
  const [step, setStep] = useState(0);
  const setQuery = () => {
    let searchParams = new URLSearchParams();

    Object.keys(postcodes).forEach((postcode) => {
      if (postcodes[postcode]) {
        searchParams.set(postcode, postcodes[postcode]);
      }
    });

    if (!postcodes.pc2) {
      setPostcodes({
        pc1: postcodes.pc1,
      });
    }

    window.history.pushState(null, "", `?${searchParams.toString()}`);
    setCurrentView("Loading");
  };
  return (
    <div className="app-body">
      <div className="app-content">
        {steps.map(({ id, Component }, index) => {
          return (
            <CSSTransition
              in={id === step}
              timeout={1000}
              classNames="ani-steps"
              key={index}
              unmountOnExit
            >
              <Component
                setStep={setStep}
                setQuery={setQuery}
                setPostcodes={setPostcodes}
                postcodes={postcodes}
              />
            </CSSTransition>
          );
        })}
      </div>
      <footer className="app-footer">
        <LocationNav step={step} setStep={setStep} />
      </footer>
    </div>
  );
};

export default LocationView;
