const getLatLng = (postcodes) => {
  let url_postcode = "https://api.postcodes.io/postcodes";

  return fetch(url_postcode, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      postcodes: Object.values(postcodes),
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      let output = [];
      data.result.forEach((result) => {
        const details = {
          postcode: result.result.postcode,
          lng: result.result.longitude,
          lat: result.result.latitude,
        };
        output.push(details);
      });
      return output;
    })
    .catch(function (error) {
      throw new Error("Postcodes not good: " + error);
    });
};

export default getLatLng;
