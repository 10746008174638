const LocationTwo = ({ setQuery, setPostcodes, postcodes }) => {
  return (
    <div className="ani-steps">
      <p>
        What postcode would you like to compare{" "}
        <span className="highlight">{postcodes.pc1}</span> to?
      </p>
      <form
        className="input-wrapper"
        onSubmit={(event) => {
          event.preventDefault();
          setQuery();
        }}
      >
        <input
          type="text"
          name="pc2"
          onChange={(event) => {
            setPostcodes({
              ...postcodes,
              pc2: event.target.value,
            });
          }}
          value={postcodes.pc2}
          placeholder="Postcode"
          maxLength="8"
        />
        <button
          className={
            postcodes.pc2.length > 5 ? "btn-submit active" : "btn-submit"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.061"
            height="10.71"
            viewBox="0 0 6.061 10.71"
          >
            <path
              d="M382.5,345.5l5,5-5,5"
              transform="translate(-382.146 -345.146)"
              fill="none"
            />
          </svg>
        </button>
      </form>
    </div>
  );
};
export default LocationTwo;
