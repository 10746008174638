const LocationCompare = ({ setStep, postcodes, setQuery }) => {
  return (
    <div className="ani-steps">
      <p>
        Would you like to compare{" "}
        <span className="highlight">{postcodes.pc1}</span> to another postcode?
      </p>
      <button className="btn-large" onClick={() => setStep(2)}>
        Yes
      </button>
      <button className="btn-large" onClick={() => setQuery()}>
        No
      </button>
    </div>
  );
};

export default LocationCompare;
