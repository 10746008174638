import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Head from "./Head";

import MainView from "./views/MainView";
import InformationView from "./views/InformationView";
import PrivacyPolicyView from "./views/PrivacyPolicyView";
import NotFoundView from "./views/NotFoundView";

import AppHeader from "./components/AppHeader";

export default function App() {
  return (
    <BrowserRouter>
      <Head />
      <div className="app">
        <AppHeader />
        <Route
          render={({ location }) => {
            return (
              <TransitionGroup component={null}>
                <CSSTransition
                  key={location.key}
                  timeout={1000}
                  classNames="app-body"
                >
                  <Switch location={location}>
                    <Route exact path="/" component={MainView} />
                    <Route path="/information" component={InformationView} />
                    <Route
                      path="/privacy-policy"
                      component={PrivacyPolicyView}
                    />
                    <Route component={NotFoundView} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            );
          }}
        />
      </div>
    </BrowserRouter>
  );
}
